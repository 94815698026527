import { css } from "@emotion/core";
import styled from '@emotion/styled';
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import HorizontalRule from "@narative/gatsby-theme-novela/src/components/HorizontalRule";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import mediaqueries from "@styles/media";
import React from "react";
import Subscriptionlanding from "./../@narative/gatsby-theme-novela/components/Subscriptionlanding";


const headingText = {
  main: "Better get me some of that leverage.",
  sub: "That Leverage. A daily newsletter full of tips, tools and tales of the new world of leverage."
} 


function Home() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Headings.h1>{headingText.main}</Headings.h1>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Headings.h3>{headingText.sub}</Headings.h3>
        </div>
        <div style={{ marginTop: "50px" }}>
        <Subscriptionlanding />
        </div>
        <div style={{ textAlign: "center", 
                      marginBottom: "50px"   }}>
        <HorizontalRule />
        <Headings.h1>Leverage?</Headings.h1>
        </div>
        <div style={{ textAlign: "center" }}>
        <Headings.h3>Naval (good guy, not well known outside tech) was right when he said:</Headings.h3>
        </div>
        <TwitterCard>
       
          <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Fortunes require leverage. Business leverage comes from capital, people, and products with no marginal cost of replication (code and media).</p>&mdash; Naval (@naval) <a href="https://twitter.com/naval/status/1002106317064949763?ref_src=twsrc%5Etfw">May 31, 2018</a></blockquote>
        
        </TwitterCard>
        <div>
        <HorizontalRule />
        </div>
        <div style={{ textAlign: "center", 
                      marginBottom: "50px"   }}>
        <Headings.h1>What you'll get</Headings.h1>
        </div>
        <div style={{ textAlign: "center", marginBottom: "50px" }}>
        <Headings.h3>Daily motivation and inspiration to get out there and make your mark. Like this:</Headings.h3>
        </div>
        <ArticleBody>
          <div style={{ marginBottom: "25px"}}>
            <Headings.h3>
            <a style={{ color: "#ff3864" }}>:: </a> 
            Satya Nadella's advice to the non-technical
            </Headings.h3>
          </div>
          <div style={{ marginBottom: "25px"}}>
          Satya Nadella goes under the radar. But when he speaks we listen - but what he said recently in Sydney was unexpected. 
          </div>
          <div style={{ marginBottom: "25px"}}>
            Nadella took over as CEO of Microsoft in 2014 and has transformed the company since then - quietly. The share price was $37 when took over - and has recently nudged $150 and the market cap has punched through $1T ('T' for trillion that is). 
          </div>
          
          <div style={{ marginBottom: "25px"}}>
            Impressed?
          </div> 
          <div style={{ marginBottom: "25px"}}>
            Just to put this achievement in perspective, when Tim Cook took over Apple in 2011 the share price was around $55 and today is $260 per share. Nadella has achieved what Cook has achieved but in 3 less years and with no iPhone and no iTunes - remember Windows phone anyway?</div> 
          <div style={{ marginBottom: "25px"}}>
          Even more impressive is that outside the tech community (and now Wall Street) few people could name Microsoft's CEO ("Is it Bill Gates still? .... or <a href="https://www.youtube.com/watch?v=lAkuJXGldrM">who was that guy who couldn't dance</a>"?). 
          </div> 
          <div style={{ marginBottom: "25px"}}>
          Nadella has kept under the radar but he has also kept focus. Bill Gates has said that missing mobile was <a href="https://mastersofscale.com/bill-gates-how-to-accelerate-history/">his greatest regret as CEO</a>. By the time Nadella took over Apple's dominance in mobile was undisputed. 
          </div> 
          <div style={{ marginBottom: "25px"}}>
          Nadella wasn't going to make that mistake twice. 
          </div> 
          <div style={{ marginBottom: "25px"}}>
          The next wave to get on was cloud - and Nadella threw everything at it. And it was right up Microsoft's alley being about a wide comprehensive network (rather than the latest application or device). 
          </div> 
          <div style={{ marginBottom: "25px"}}>
          <a href="https://www.afr.com/technology/i-represent-it-all-in-conversation-with-microsoft-s-satya-nadella-20191120-p53cax">Nadella was recently interviewed</a> and discussed the cloud but also gave some nuggets of advice for anyone. And some unexpected advice. 
          </div> 
          <div style={{ marginBottom: "25px"}}>
          You know the old quib - never ask your barber if you need a haircut? Which you could rephrase - never ask a tech company if you need more tech right? 
          </div> 
          <div style={{ marginBottom: "25px"}}>
          But listen to what Nadella has to say:
          </div>
          <div style={{ marginBottom: "25px", fontWeight: "bold"}}>
          My view of the world is as follows: if every company is going to be a software company then what they need is more software capability that needs to get built inside.
          </div>
          <div style={{ marginBottom: "25px", fontWeight: "bold"}}>
          Whether you're in mining, whether you're in energy, whether you're in banking – what it is doesn't matter. You all will want to build your own technology capability. Satya Nadella
          </div>
          <div style={{ marginBottom: "25px"}}>
          What?
          </div>
          <div style={{ marginBottom: "25px"}}>
          The head of Microsoft telling you to build your own technology capability. Aren't they selling that?
          </div>
          <div style={{ marginBottom: "25px"}}>
          Gotta hand it to Nadella - he's a masterful salesman here. To sell Microsoft cloud products each company probably does need some in-house skills to implement; so he has mastered the art of selling-while-looking-to-do-the-opposite.

          </div>
          <div style={{ marginBottom: "25px"}}>
          But taking a step back can you apply this to help get unstuck?
          </div>
          <div style={{ marginBottom: "25px"}}>
          First up, if you are lawyer feeling stuck and a world away from the giddy heights of Silicon Valley (or even Microsoft's Seattle) - use Nadella's advice in your workplace. Get buy-in for small tech projects that can be good for the business and build out your exposure and skill set. Small steps are good steps.

          </div>
          <div style={{ marginBottom: "25px"}}>
          But then think about how to build your own technological capability. Which doesn't mean learning to code (though it could!). It will mean learning no-code tools and it could mean teaming up with someone to complement your skills.
          </div>
          <div style={{ marginBottom: "25px"}}>
          As the primary teacher said (exasperated) on the morning of the concert to the recorder class: "one of you is going to have to blow!"; so one of you is going to have to code.
          </div>
          <div>Ready?</div>


        </ArticleBody>
        <HorizontalRule />
        
        <div style={{ textAlign: "center" }}>
        <Headings.h1>Let's do this!</Headings.h1>
        </div>

        <Subscriptionlanding />
          
          <Row>
            <Col size={1}></Col>
            {/* <Col size={1}>and another one</Col>
            <Col size={1}>and another one</Col> */}
          </Row>
      
      </Section>
    </Layout>
  );
}

export default Home;

const commonStyles = p => css`
  font-weight: bold;
  color: ${p.theme.colors.primary};
  font-family: ${p.theme.fonts.serif};
`;


const ArticleBody = styled.article`
  ${commonStyles};  
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 68px;
  transition: background 0.2s linear;
  margin: 5px 200px;

  a {
    color: #ff3864;
  }

  ${mediaqueries.desktop`
    padding-left: 53px;
  `}
  
  ${mediaqueries.tablet`
    padding: 70px 0 80px;
  `}

  ${mediaqueries.phablet`
    padding: 25px;
    margin: 0px;
  `}
`;

const TwitterCard = styled.div`
  margin: 50px 300px;
  padding: 25px;
  
  ${mediaqueries.desktop`
    padding-left: 53px;
    margin: 50px 100px;
  `}
  
  ${mediaqueries.tablet`
    padding: 70px 0 80px;
  `}

  ${mediaqueries.phablet`
    padding: 25px;
    margin: 25px 0;
  `}
`;


export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${mediaqueries.phablet`
    flex-direction: column;
  `}
`;

export const Col = styled.div`
  flex: ${(props) => props.size};
  // border: 1px solid #f0f0f0;
  width: 150px;
  // height: 100px;
  // margin: 50px;
`;

